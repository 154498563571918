import { navigate } from 'gatsby';
import React from 'react';

function CityCard({ id, img, city, places }) {
  return (
    <div
      onClick={() => navigate(`/events?city=${city.toLowerCase()}`)}
      className="city-card"
      key={id}
    >
      <div className="city-card__image">
        <img className="thumbnail" src={img} alt={city} />
      </div>
      <div className="city-card-link">
        <div className="city-card__text">
          <h3 className="city-card__text--title">{city}</h3>
          <p className="city-card__text--description">
            Oplev blandt andet <br />
            <span>{places}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default CityCard;
