import React, { useContext, useState } from 'react';
import useSearch from '../../hooks/useSearch';
import { EventsContext } from '../../provider/EventProvider';
import { DelayContext } from '../../provider/DelayProvider';
import CityCards from '../CityCards';
import CategoryCards from '../CategoryCards';
import useWindowsize from '../../hooks/useWindowSize';
import InfoCards from '../InfoCards';

function Frontpage() {
  const [windowWidth] = useWindowsize();
  const { state } = useContext(EventsContext);
  const { delay } = useContext(DelayContext);
  const [keyword, setKeyword] = useState('');
  const [handleClick] = useSearch(keyword);

  const handleSearch = (e) => {
    e.preventDefault();
    handleClick();
  };

  const handleEnter = (e) => {
    if (e && e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
      handleClick();
    }
  };

  return (
    <>
      <section
        className={`explore-container${
          state.isSearching || delay ? ' searching' : ''
        }`}
      >
        <form>
          <div className="explore-container__search">
            <h1>Find begivenheder og events i hele landet</h1>
            <div className="search-field">
              <input
                onKeyPress={(e) => handleEnter(e)}
                placeholder="Søg efter begivenheder"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <button
                className="explore-container__button"
                onClick={(e) => handleSearch(e)}
              >
                {windowWidth > 1024 ? 'Søg events' : 'Søg'}
              </button>
            </div>
          </div>
        </form>
        <CityCards />
        <CategoryCards />
        <InfoCards />
      </section>
    </>
  );
}

export default Frontpage;
