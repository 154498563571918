import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Frontpage from '../components/Frontpage';

function IndexPage() {
  return (
    <>
      <SEO />
      <Layout>
        <Frontpage />
      </Layout>
    </>
  );
}

export default IndexPage;
