import React from 'react';
import cities from './cities';
import CityCard from './citycard';

function CityCards() {
  return (
    <div className="city-cards">
      <h2 className="city-cards__title">Danmarks største byer</h2>
      <div className="city-cards__content">
        {cities.map((city) => (
          <CityCard
            key={city.id}
            id={city.id}
            img={city.img}
            city={city.city}
            places={city.places}
          />
        ))}
      </div>
    </div>
  );
}

export default CityCards;
