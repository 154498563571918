import React from 'react';
import { FaSistrix, FaBullhorn, FaCheck } from 'react-icons/fa';

function InfoCards() {
  return (
    <div className="info-cards">
      <div className="info-cards__card">
        <div className="info-cards__card--icon">
          <FaSistrix size={42} />
        </div>
        <div className="info-cards__card--text">
          <h2>Samling af events</h2>
          <p>
            Med formål om at gøre det nemmere at finde begivenheder i Danmark,
            har vi sammen med flere billetudbydere samlet tusindevis af events
            og begivenhederne, med mulighed for at se hvor og hvornår det finder
            sted.
          </p>
        </div>
      </div>
      <div className="info-cards__card">
        <div className="info-cards__card--icon">
          <FaBullhorn size={42} />
        </div>
        <div className="info-cards__card--text">
          <h2>Planlægning</h2>
          <p>
            Ved du hvad der skal ske næste gang du besøger København eller
            Århus? Eller hvad der sker på din fødselsdag? Der kan være mange
            spørgsmål. Med Zooticket kan du søge events og begivenheder ud fra
            valgte datoer, som forhåbentlig kan hjælpe dig med at finde det
            event du søger.
          </p>
        </div>
      </div>
      <div className="info-cards__card">
        <div className="info-cards__card--icon">
          <FaCheck size={42} />
        </div>
        <div className="info-cards__card--text">
          <h2>Kvit og frit</h2>
          <p>
            Zooticket er gratis at anvende. Vi er ikke selv ejer at billetterne,
            men er i samarbejde med billetudbydere, om at få samlet
            begivenhederne og gøre det nemmere at få overblikket over
            begivenhederne, rundt omkring i landet
          </p>
        </div>
      </div>
    </div>
  );
}

export default InfoCards;
